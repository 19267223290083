import { INGXLoggerConfig, INGXLoggerMetadata, INGXLoggerWriterService, NGXLoggerWriterService } from "ngx-logger";
import { IMessage, CrossAppMessagingService } from "../_services/crossApp-messaging.service";



export class NativeLogger extends NGXLoggerWriterService {
  constructor(
    private crossAppMessaging: CrossAppMessagingService,
  ) {
    super(1);
  }

  override writeMessage(metadata: INGXLoggerMetadata, config: INGXLoggerConfig): void {

    var msk: IMessage = {
      action: 'log',
      data: JSON.stringify({
        message: metadata.message,
        level: metadata.level,
        additional: metadata.additional,
      })
    };
    this.crossAppMessaging?.sendRawMessage(msk)
  }

}
