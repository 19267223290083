import { GenericItemApiResponse, GenericListApiResponse } from 'src/app/core/_interface/apiResponse';
import { Order, OrderItem, OrderItemStatus, OrderItemToCancel, OrderStatusChangeItem, PlacedOrder, SearchOrderOptions } from 'src/app/core/_interface/order';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../_interface/apiResponse';
import { DeliveryType, OrderDeliveryDetail, OrderStatus } from '../_interface/order';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(public http: HttpClient,
    private logger: NGXLogger
  ) { }

  setStatus(orderID: number,
    status: OrderStatus,
    group?: string,
    preparationTime?: number,
    dueDate?: Date,
    rejectReason?: string): Observable<boolean> {

    this.logger.debug('OrderService.setStatus', orderID, status, group, preparationTime, dueDate, rejectReason);

    const url = environment.apiBase + `order/SetStatus`;
    var data = {
      orderID: orderID,
      status: status,
      group: group,
      preparationTime: preparationTime,
      dueDate: dueDate,
      rejectReason
    };

    return this.http.post<GenericItemApiResponse<string>>(url, data)
      .pipe(
        map(response => {
          if (response.success) {
            return response.success
          }

          throw response.item;
        })
      );
  }

  setOrderItemStatus(item: OrderItem | null, status: OrderItemStatus, ids?: number[]) {
    this.logger.debug('OrderService.setOrderItemStatus', item, status, ids);

    const url = environment.apiBase + `order/SetOrderItemStatus`;
    var data = {
      orderItemID: item?.orderItemID,
      status,
      ids: ids
    };

    return this.http.post<ApiResponse>(url, data)
      .pipe(
        map(response => {
          if (response.success) {
            return response.success
          }

          throw response;
        })
      );
  }

  save(companyID: number, order?: Order | null) {
    this.logger.debug('OrderService.save', order);

    const url = environment.apiBase + `order/create2`;

    var data = {
      companyID,
      order,
      employee: true
    }

    return this.http.post<GenericItemApiResponse<Order>>(url, data).pipe(
      map(result => {
        this.logger.debug('OrderService.save result', result);
        if (result.success) {
          return new Order(result.item);
        }

        throw result;
      })
    );
  }

  cancelItems(tableID: number, items?: OrderItemToCancel[]) {
    this.logger.debug('OrderService.cancelItems', items);
    const url = environment.apiBase + `order/CancelOrderItems`;
    var data = {
      tableID: tableID,
      orderItems: items
    }

    return this.http.post<ApiResponse>(url, data).pipe(
      map(result => {
        this.logger.debug('OrderService.cancelItems result', result);
        if (result.success) {
          return true;
        }

        throw result.errorDescription;
      })
    );
  }

  search(searchParam: SearchOrderOptions) {
    const url = environment.apiBase + `order/get`;

    return this.http.post<GenericListApiResponse<Order>>(url, searchParam)
      .pipe(
        map(response => {
          if (response.success) {
            var list: Order[] = [];
            response.items.forEach(i => {
              var no = new Order(i);

              list.push(no);
            });

            return list;
          }
          return null;
        }),
        tap(items => {
          items?.forEach(o => o.created = new Date(o.created));
        })
      );
  }

  getStatusChangeLog(orderID: number) {
    const url = environment.apiBase + `order/GetStatusChangeLog/${orderID}`;

    return this.http.get<GenericListApiResponse<OrderStatusChangeItem>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          throw response;
        })
      );
  }


  getActiveInstantOrders(companyID: number) {

    var searchParam: SearchOrderOptions = {
      companyID: companyID,
      status: [OrderStatus.Accepted, OrderStatus.New, OrderStatus.InProduction, OrderStatus.Ready, OrderStatus.NewPreorder, OrderStatus.Pending],
      deliveryType: [DeliveryType.Pickup, DeliveryType.Delivery]
    };

    return this.search(searchParam);
  }

  getOrder(orderID: number) {

    const url = environment.apiBase + `order/GetOrder?orderID=${orderID}`;

    return this.http.get<GenericItemApiResponse<Order>>(url).pipe(
      map(
        data => {
          if (data.success) {
            return new Order(data.item);
          }

          throw data.errorDescription;
        }
      )
    );
  }

  customerOrderHistory(companyID: number, customerID?: number) {

    const url = environment.apiBase + `order/customerOrderHistory?companyID=${companyID}&customerID=${customerID}`;

    return this.http.get<GenericListApiResponse<PlacedOrder>>(url).pipe(
      map(
        data => {
          if (data.success) {
            return data.items;
          }

          return [];
        }
      )
    );
  }


  getOrderPDF(orderID: number) {
    const url = environment.apiBase + `ordersPDF/order/${orderID}`;

    const options = { responseType: 'blob' as 'json' };

    return this.http.get<Blob>(url, options).pipe(
      map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        }),
      catchError(e => {
        throw e;
      })
    );

  }



  getOrderListPDF(param: SearchOrderOptions) {
    const url = environment.apiBase + `ordersPDF/orderlist/`;

    const options = { responseType: 'blob' as 'json' };

    return this.http.post<Blob>(url, param, options).pipe(
      map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        }),
      catchError(e => {
        throw e;
      })
    );

  }

  getProductListPDF(param: SearchOrderOptions) {
    const url = environment.apiBase + `ordersPDF/ProductList/`;

    const options = { responseType: 'blob' as 'json' };

    return this.http.post<Blob>(url, param, options).pipe(
      map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        }),
      catchError(e => {
        throw e;
      })
    );

  }
}
