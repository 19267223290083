<svg:svg
  appResize
  (resized)="resized($event)"
  class="box"
  #layoutContainer
  preserveAspectRatio="xMinYMin meet"
  viewBox="0 0 1000 500"
>
  <g class="layoutContainer">
    <defs>
      <filter id="f1" x="-20%" y="-20%" width="200%" height="200%">
        <feOffset result="offOut" in="SourceAlpha" dx="5" dy="5" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
    </defs>
    <g
    class="rotateGroup"
      [style.transform]="
        'rotate(' + (rotation || 0) + 'deg) translateY(' + translateY + '%)'
      "
    >
      <image
        #layoutBackground
        id="background"
        x="0"
        y="0"
        (load)="backgroundLoaded()"
        attr.xlink:href="api/table/layoutbackground/?companyID={{
          session.companyID
        }}&group={{ selectedGroup }}"
      ></image>
      <ng-container *ngIf="tables">
        <!-- filter="url(#f1)" -->
        <g
          *ngFor="let table of tables"
          #item
          filter="url(#f1)"
          [matContextMenuTriggerFor]="editable ? editContextMenu : null"
          [matMenuTriggerData]="{
            position: table
          }"
          cdkDrag
          [cdkDragDisabled]="!editable"
          [cdkDragData]="table"
          [cdkDragFreeDragPosition]="{
            x: (table.xPosition * backgroundRect.width) / 100 || 50,
            y: (table.yPosition * backgroundRect.height) / 100 || 50
          }"
          (cdkDragEnded)="setPosition($event)"
          [class.edit]="editable"
          [class.loading]="table.isLoading"
          class="layoutItem"
          app-layout-item="table"
          [data]="table"
          [itemTextTemplate]="itemTextTemplate"
          (click)="itemClick.emit(table)"
        ></g>
      </ng-container>
    </g>
  </g>
</svg:svg>

<mat-menu #editContextMenu>
  <ng-template matMenuContent let-item="position">
    <button mat-menu-item [matMenuTriggerFor]="tableTypes">Tip</button>
    <button mat-menu-item [matMenuTriggerFor]="tableGroups">Grupa</button>

    <mat-menu #tableTypes>
      <button mat-menu-item (click)="setTableType(item, TableType.Round)">
        Krug
      </button>
      <button mat-menu-item (click)="setTableType(item, TableType.Square)">
        Kvadrat
      </button>
      <button mat-menu-item (click)="setTableType(item, TableType.Square45)">
        Kvadrat45
      </button>
      <button mat-menu-item (click)="setTableType(item, TableType.Rectangle)">
        Pravougaonik
      </button>
      <button
        mat-menu-item
        (click)="setTableType(item, TableType.RectangleVertical)"
      >
        Pravougaonik 90
      </button>
    </mat-menu>

    <mat-menu #tableGroups>
      <button
        mat-menu-item
        *ngFor="let groupName of session.tables | async | tableGroups"
        (click)="setTableGroup(item, groupName)"
      >
        {{ groupName }}
      </button>
      <button mat-menu-item (click)="setTableGroup(item)">Nova</button>
    </mat-menu>
  </ng-template>
</mat-menu>
