import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, Subscription, timer } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { AppInjector } from '../core.module';
declare var hybridWebViewHost: any | undefined;

export enum MessageType {
  Message = 0,
  Invoke = 1
}

export interface IMessage {
  action: string;
  requestID?: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class CrossAppMessagingService {
  private subscriptions: { [key: string]: Subject<string> } = {};

  private _callbacks: {
    [key: string]: {
      resolve: (value: any) => void,
      reject: (err: any) => void,
      timer: Subscription,
      returnJSON: boolean
    }
  } = {};

  _logger?: NGXLogger
  private get logger() {
    if (this._logger == null) {
      this._logger = AppInjector.get(NGXLogger);
    }

    return this._logger;
  }

  constructor(
  ) {
    (window as any).crossAppMessage = (message: any) => {
      try {
        var jMsg = JSON.parse(message);
      }
      catch (e) {
        this.logger.error("Error parsing message", message);
        return
      }
      var requestID = jMsg.requestID;

      if (requestID) {
        this.logger.trace("Received response for request " + requestID, jMsg);

        var promise = this._callbacks[requestID];

        if (promise) {
          delete this._callbacks[requestID];

          promise.timer.unsubscribe();

          var msg = jMsg.data;
          if (promise.returnJSON) {
            if (msg != null) {
              msg = msg.replace(/'/g, '"');

              var msg = JSON.parse(msg);
            }
          }
          promise.resolve(msg);
        }
      }
      else {
        this.logger.trace("Received message" + jMsg.type, jMsg);

        var type = jMsg.type;
        this.subscriptions[type]?.next(jMsg.data);
      }
    }
  }
  onMessage(messageType: string): Subject<string> {

    if (!this.subscriptions[messageType]) {
      this.subscriptions[messageType] = new Subject<string>();
    }

    return this.subscriptions[messageType];
  }

  sendRawMessage(message: IMessage, waitForResponse: boolean = false, waitTimeout: number = 60000, retrunJSON = false) {

    if (!message.requestID && waitForResponse) {
      message.requestID = Math.random().toString();
    };

    var m = JSON.stringify(message);

    // console.log(new Date, message.action, message.requestID);


    this.sendMessage(0, m);

    if (waitForResponse) {
      return new Promise<any>((resolve, reject) => {
        this._callbacks[message.requestID!] = {
          resolve: resolve,
          reject: reject,
          timer: timer(waitTimeout).subscribe(() => {
            this.logger.warn("Request timeout - rejecting", message.requestID, message.action);
            reject()
          }),
          returnJSON: retrunJSON
        };

      });
    }
    else {
      return Promise.resolve();
    }


  }

  sendInvokeMessage(methodName: string, paramValues: any[]) {
    if (typeof paramValues !== 'undefined') {
      if (!Array.isArray(paramValues)) {
        paramValues = [paramValues];
      }
      for (var i = 0; i < paramValues.length; i++) {
        paramValues[i] = JSON.stringify(paramValues[i]);
      }
    }

    this.sendMessage(1, JSON.stringify({ "MethodName": methodName, "ParamValues": paramValues }));
  }

  private sendMessage(messageType: MessageType, messageContent: string) {
    if ((environment as any)["nativeApp"] !== true) {
      return;
    }

    var message = JSON.stringify({ "MessageType": messageType, "MessageContent": messageContent });
    var w = window as any;
    if (w.chrome && w.chrome.webview) {
      // Windows WebView2
      w.chrome.webview.postMessage(message);
    }
    else if (w.webkit && w.webkit.messageHandlers && w.webkit.messageHandlers.webwindowinterop) {
      // iOS and MacCatalyst WKWebView
      w.webkit.messageHandlers.webwindowinterop.postMessage(message);
    }
    else if (typeof hybridWebViewHost !== 'undefined') {
      // Android WebView
      hybridWebViewHost.sendMessage(message);
    }
  }
}
