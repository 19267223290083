import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { Table, TableLayoutItem, TableType } from 'src/app/core/_interface/table';
import Swal from 'sweetalert2';
import { SessionService } from '../../_services/session.service';
import { CdkDragEnd } from '@angular/cdk/drag-drop';

import { Subject, takeUntil } from 'rxjs';
import SvgPanZoom from 'svg-pan-zoom';

@Component({
  selector: 'app-table-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class TableLayoutComponent implements AfterViewInit, OnDestroy {
  TableType = TableType;
  rotation = 0; //90;
  translateY = 0; // -100;

  private _autoRotate = false;
  public get autoRotate() {
    return this._autoRotate;
  }

  @Input()
  public set autoRotate(value) {
    this._autoRotate = value;
    if (!this.layoutContainer) {
      return;
    }
    this.resized({ width: this.layoutContainer.nativeElement.clientWidth, height: this.layoutContainer.nativeElement.clientHeight });
  }

  private panZoom?: SvgPanZoom.Instance;
  @Input() tables: TableLayoutItem[] = [];

  @Input() selectedGroup: string = "";
  @Input() editable = false;
  @Input() itemTextTemplate: TemplateRef<any> | undefined;
  @Output() itemClick = new EventEmitter<any>();
  @Input() initialView: "contain" | "cover" = "contain";
  @Input() mouseWheelZoomEnabled = true;
  @Input() dblClickZoomEnabled = true;

  destroyed = new Subject<void>();

  backgroundRect: DOMRect = new DOMRect(0, 0, 1000, 500);

  @ViewChild("layoutContainer") layoutContainer!: ElementRef<SVGElement>;
  @ViewChild("layoutBackground") layoutBackground!: ElementRef<SVGImageElement>;


  constructor(
    public session: SessionService,
    private cdr: ChangeDetectorRef
  ) {

    session.tables.pipe(
      takeUntil(this.destroyed)
    ).subscribe((e) => {
      this.selectedGroup = e.map(i => i.group)
        .filter(i => i)
        .sort((a, b) => a.localeCompare(b))[0] || "";

      this.cdr.markForCheck();
    }
    );
  }

  resized(newSize: { width: number, height: number }) {
    //console.log("resized: ", newSize);
    this.layoutContainer.nativeElement.setAttribute("viewBox", `0 0 ${newSize.width} ${newSize.height}`);

    this.setPanZoom();
    this.autoZoom();

    if (!this.autoRotate) {
      return;
    }

    if (newSize.width < newSize.height) {
      this.setPortrait();
    }
    else {
      this.setLandscape();
    }

  }


  setPortrait() {
    if (this.rotation == 90) {
      return;
    }

    this.rotation = 90;
    this.translateY = -100;
    this.zoomContain();
  }

  setLandscape() {
    if (this.rotation == 0) {
      return;
    }
    this.rotation = 0;
    this.translateY = 0;
    this.zoomContain();

  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  backgroundLoaded() {
    this.backgroundRect = this.layoutBackground.nativeElement.getBBox();
    this.setPanZoom();

    this.autoZoom();
  }

  autoZoom() {
    switch (this.initialView) {
      case "contain":
        this.zoomContain();
        break;
      case "cover":
        this.zoomCover();
        break;
    }
  }

  ngAfterViewInit(): void {
    this.resized({ width: this.layoutContainer.nativeElement.clientWidth, height: this.layoutContainer.nativeElement.clientHeight });
  }

  setPanZoom() {
    var el = document.querySelector('#background') as SVGElement;

    this.panZoom = SvgPanZoom(this.layoutContainer.nativeElement, {
      zoomEnabled: true,
      mouseWheelZoomEnabled: this.mouseWheelZoomEnabled,
      dblClickZoomEnabled: this.dblClickZoomEnabled,
      panEnabled: true,
      maxZoom: 1000,
      eventsListenerElement: el,
      fit: true
    });
    // this.panZoom.fit();

    this.panZoom.setBeforePan((oldPan: any, newPan: any) => {
      var layoutRect = this.layoutContainer.nativeElement.getBoundingClientRect();

      var backgroundRect = this.layoutBackground.nativeElement.getBBox();

      var size = this.panZoom!.getSizes();

      var width = this.rotation == 0 ? backgroundRect.width : backgroundRect.height;
      var height = this.rotation == 0 ? backgroundRect.height : backgroundRect.width;



      var x = Math.min(0, Math.max(newPan.x, layoutRect.width - width * size.realZoom));
      var y = Math.min(0, Math.max(newPan.y, layoutRect.height - height * size.realZoom));

      return { x, y };
    }
    );
  }

  zoomIn() {
    this.panZoom!.zoomIn();
  }

  zoomOut() {
    this.panZoom!.zoomOut();
  }

  zoomCover() {
    var layoutRect = this.layoutContainer.nativeElement.getBoundingClientRect();

    var backgroundRect = this.backgroundRect; // .layoutBackground.nativeElement.getBBox();
    if (backgroundRect.width == 0 || backgroundRect.height == 0) {
      return;
    }
    var size = this.panZoom!.getSizes();

    var width = this.rotation == 0 ? backgroundRect.width : backgroundRect.height;
    var height = this.rotation == 0 ? backgroundRect.height : backgroundRect.width;


    var zoomHeight = layoutRect.height / (height);
    var zoomWidth = layoutRect.width / (width);

    var zoom = Math.max(zoomHeight, zoomWidth)

    this.panZoom!.pan({ x: 0, y: 0 });
    //console.log(zoom);

    this.panZoom!.zoom(zoom);
  }

  zoomContain() {
    var layoutRect = this.layoutContainer.nativeElement.getBoundingClientRect();

    var backgroundRect = this.layoutBackground.nativeElement.getBBox();

    if (backgroundRect.width == 0 || backgroundRect.height == 0) {
      return;
    }


    var width = this.rotation == 0 ? backgroundRect.width : backgroundRect.height;
    var height = this.rotation == 0 ? backgroundRect.height : backgroundRect.width;


    var zoomHeight = layoutRect.height / (height);
    var zoomWidth = layoutRect.width / (width);

    var zoom = Math.min(zoomHeight, zoomWidth)
    this.panZoom!.zoom(zoom);

    var newPos = { x: (layoutRect.width - backgroundRect.width * zoom) / 2, y: (layoutRect.height - backgroundRect.height * zoom) / 2 };

    //console.log(zoom);
    this.panZoom!.pan(newPos);

    //    this.panZoom!.fit();


  }


  setPosition(e: CdkDragEnd) {
    var position = e.source.getFreeDragPosition();

    var table = e.source.data as Table;
    table.xPosition = Math.round(position.x * 100 / this.backgroundRect.width);
    table.yPosition = Math.round(position.y * 100 / this.backgroundRect.height);
  }

  setTableType(table: Table, type: TableType) {
    table.type = type;
  }

  setTableGroup(table: Table, group?: string) {
    if (!group) {
      Swal.fire({
        title: 'Naziv grupe',
        input: 'text',
        inputPlaceholder: 'Unesite naziv grupe',
      }).then((result) => {
        if (result.isConfirmed) {
          this.setTableGroup(table, result.value);
        }
      }
      );
      return;
    }
    table.group = group;
  }

  setBackground(file: File) {
    this.layoutBackground.nativeElement.href.baseVal = URL.createObjectURL(file);

  }
}
