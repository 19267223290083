import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GenericListApiResponse, GenericItemApiResponse, ApiResponse } from 'src/app/core/_interface/apiResponse';
import { Group } from 'src/app/core/_interface/product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  groupSaved = new Subject<Group>();

  constructor(private http: HttpClient) {

  }

  translate(companyID: number, from: any, to: any) {
    const url = environment.apiBase + `group/translate?companyID=${companyID}&from=${from}&to=${to}`;
    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => {
          if (response.success) {
            return true;
          }
          throw response;
        })
      );
  }

  get(companyID: number): Observable<Group[] | undefined> {
    const url = environment.apiBase + `group/get?companyID=${companyID}`;
    return this.http.get<GenericListApiResponse<Group>>(url)
      .pipe(
        map(response => {
          if (response.success) {
            return response.items;
          }
          return undefined;
        })
      );
  }

  save(companyID: number, product: Group): Observable<Group | undefined> {
    const url = environment.apiBase + `group/save?companyID=${companyID}`;
    return this.http.post<GenericItemApiResponse<Group>>(url, product)
      .pipe(
        map(response => {
          if (response.success) {
            return response.item;
          }
          return undefined;
        }),
        tap(g => {
          if (g){
            this.groupSaved.next(g)
          }
        } )
      );
  }

  saveGroupOrder(companyID: number, order: number[]): Observable<boolean> {
    const url = environment.apiBase + `group/saveOrder?companyID=${companyID}`;
    return this.http.post<ApiResponse>(url, order)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }


  saveProductOrder(companyID: number, groupID: number, order: number[]): Observable<boolean> {
    const url = environment.apiBase + `group/SaveProductOrder?companyID=${companyID}&groupID=${groupID}`;
    return this.http.post<ApiResponse>(url, order)
      .pipe(
        map(response => {
          return response.success;
        })
      );
  }


  setIsAvailable(groupID: number, isAvailable: boolean) {
    const url = environment.apiBase + `group/SetIsAvailable?groupID=${groupID}&isAvailable=${isAvailable}`;

    return this.http.post<ApiResponse>(url, null)
      .pipe(
        map(response => {
          if (!response.success){
            throw response;
          }
          return response.success;
        })
      );
  }

}
