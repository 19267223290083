<ng-container [ngSwitch]="data.type">
  <svg
    viewBox="0 0 120 120"
    width="50"
    height="50"
    *ngSwitchCase="TableType.Round"
  >
    <path
      #item
      d="M 60 10 a 1 1 0 0 1 0 100 a 1 1 0 0 1 0 -100"
      pathLength="720"
      [attr.fill]="data.fillColor || fillColor"
      stroke="black"
    />
    <ng-container
      [ngTemplateOutlet]="itemTextTemplate || defaultText"
      [ngTemplateOutletContext]="{ $implicit: data }"
    ></ng-container>
  </svg>

  <svg
    viewBox="0 0 220 120"
    width="90"
    height="50"
    *ngSwitchCase="TableType.Rectangle"
  >
    <path
      #item
      d="M 110 10 l 100 0 l 0 100 l -200 0 l 0 -100 z"
      stroke="black"
      [attr.fill]="data.fillColor || fillColor"
    />

    <ng-container
      [ngTemplateOutlet]="itemTextTemplate || defaultText"
      [ngTemplateOutletContext]="{ $implicit: data }"
    ></ng-container>
  </svg>

  <svg
    viewBox="0 0 120 220"
    width="50"
    height="90"
    *ngSwitchCase="TableType.RectangleVertical"
  >
    <path
      #item
      d="M 60 10 l 50 0 l 0 200 l -100 0 l 0 -200 z"
      stroke="black"
      [attr.fill]="data.fillColor || fillColor"
    />
    <ng-container
      [ngTemplateOutlet]="itemTextTemplate || defaultText"
      [ngTemplateOutletContext]="{ $implicit: data }"
    ></ng-container>
  </svg>

  <svg
    viewBox="0 0 120 120"
    width="50"
    height="50"
    *ngSwitchCase="TableType.Square"
  >
    <path
      #item
      d="M 60 10 l 50 0 l 0 100 l -100 0 l 0 -100 z"
      stroke="black"
      [attr.fill]="data.fillColor || fillColor"
    />

    <ng-container
      [ngTemplateOutlet]="itemTextTemplate || defaultText"
      [ngTemplateOutletContext]="{ $implicit: data }"
    ></ng-container>
  </svg>

  <svg
    viewBox="0 0 120 120"
    width="70"
    height="70"
    *ngSwitchCase="TableType.Square45"
  >
    <path
      #item
      d="M 60 10 l 50 50 l -50 50 l -50 -50 z"
      stroke="black"
      [attr.fill]="data.fillColor || fillColor"
    />

    <ng-container
      [ngTemplateOutlet]="itemTextTemplate || defaultText"
      [ngTemplateOutletContext]="{ $implicit: data }"
    ></ng-container>
  </svg>
</ng-container>

<ng-template #defaultText>
  <svg:text
    x="50%"
    y="50%"
    font-size="2em"
    dominant-baseline="middle"
    text-anchor="middle"
  >
    {{ data.name }}
  </svg:text>
</ng-template>
