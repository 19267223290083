import { DomChangedDirective } from './_directives/dom-changed-directive.directive';
import { CrossAppMessagingService } from 'src/app/core/_services/crossApp-messaging.service';
import { FilterOrdersByStatusPipe } from './_pipes/filter-orders.pipe';
import { EventNamePipe } from './_pipes/event-name.pipe';
import { RouterModule } from '@angular/router';
import { GoogleSigninButtonDirectiveDirective } from './_directives/google-signin-button-directive.directive';
import { ToDeliveryTypeNamePipe } from './_pipes/ToDeliveryTypeName.pipe';
import { GroupBoxComponent } from './group-box/group-box.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { FullNamePipe } from './_pipes/full-name.pipe';
import { ToProductPipe } from './_pipes/ToProduct.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LogoutComponent } from './logout/logout.component';
import { LoginComponent } from './login/login.component';
import { AppVersionCheckService } from './_services/app-version-check.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProductsInGroupPipe } from './_pipes/productsInGroup.pipe';
import { ToAllergenNamePipe } from './_pipes/ToAllergenName.pipe';
import { ToAllergenClassPipe } from './_pipes/ToAllergenClass.pipe';
import { AccessDeniedInterceptor } from './accessDeniedInterceptor';

import { TranslationComponent } from './translation/translation.component';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NameTranslationEditComponent } from './name-translation-edit/name-translation-edit.component';
import { OrderStatusPipe } from './_pipes/orderStatus.pipe';
import { EnumCheckboxSelectorComponent } from './enum-checkbox-selector/enum-checkbox-selector.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { EnumEnumeratorPipe } from './_pipes/enumEnumerator.pipe';
import { HasPermissionPipe } from './_pipes/hasPermission.pipe';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { IsBusyDirective } from './_directives/is-busy.directive';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { ToBundleNamePipe } from './_pipes/ToBundleName.pipe';
import { QRCodeScannerComponent } from './qrcode-scanner/qrcode-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InViewDirective } from './_directives/inView.directive';
import { GetProductByIDPipe } from './_pipes/getProductByID.pipe';
import { ParentProductPipe } from './_pipes/parentProduct.pipe';
import { GroupOrderItemModifiersPipe } from './_pipes/groupOrderItemModifiers.pipe';
import { OrderItemDetailsComponent } from './order-item-details/order-item-details.component';
import { ToGroupIconClassPipe } from './_pipes/ToGroupIconClass.pipe';
import { ChildProductsPipe } from './_pipes/ChildProducts.pipe';
import { ToProductsPipe } from './_pipes/ToProducts.pipe';
import { SortPipe } from './_pipes/sort.pipe';
import { AuthTokenLoadedResolver } from './_helpers/auth-token-loaded-resolver';
import { RouteLoadingComponent } from './route-loading/route-loading.component';
import { SaveButtonComponent } from './save-button/save-button.component';
import { OrderStatusSelectorComponent } from './order-status-selector/order-status-selector.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TableGroupsPipe } from './_pipes/table-groups.pipe';
import { NoWebsocketConnectionComponent } from './no-websocket-connection/no-websocket-connection.component';
import { StickyThingDirective } from './_directives/sticky-thing.directive';
import { StatusAvailablePipe } from './_pipes/status-available.pipe';
import { TimeSincePipe } from './_pipes/time-since.pipe';
import { ReduceStringArrayPipe } from './_pipes/reduce-string-array.pipe';
import { SortOrdersByDatePipe } from './_pipes/sort-orders-by-date.pipe';
import { ToggleFullScreenComponent } from './toggle-full-screen/toggle-full-screen.component';
import { MatKeyboardModule } from 'angular-onscreen-material-keyboard';
import { VirtualKeyboardComponent } from './virtual-keyboard/virtual-keyboard.component';
import { OrderDeliveryTypeIconComponent } from './order-delivery-type-icon/order-delivery-type-icon.component';
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component';
import { VersionComponent } from './version/version.component';
import { ChargeComponent } from './charge/charge.component';
import { PaymentMethodNamePipe } from './_pipes/payment-method-name.pipe';
import { IsPaymentTypeEnabledPipe } from './_pipes/is-payment-type-enabled.pipe';

import { registerLocaleData } from '@angular/common';
import localeSr from '@angular/common/locales/sr';
import { HasAdminPermissionPipe } from './_pipes/hasAdminPermission.pipe';
import { ImageSizePipe } from './_pipes/imageSize.pipe';
import { WoltOrderDetailsComponent } from './wolt-order-details/wolt-order-details.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PropertyItemComponent } from './property-item/property-item.component';
import { WoltOrderStatusNamePipe } from './wolt-order-details/wolt-order-status-name.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SelectPreparationTimeComponent } from './select-preparation-time/select-preparation-time.component';
import { PreserveNewLinePipe } from './_pipes/preserve-new-line.pipe';
import { SelectRejectReasonComponent } from './select-reject-reason/select-reject-reason.component';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { ProviderIconComponent } from './provider-icon/provider-icon.component';

import { DragToScrollDirective } from './_directives/drag-to-scroll.directive';
import { OrderIconsComponent } from './order-icons/order-icons.component';
import { MrDOrderDetailsComponent } from './mr-dorder-details/mr-dorder-details.component';
import { CodeSigninComponent } from './code-signin/code-signin.component';
import { SplitLettersDirective } from './code-signin/split-letters.directive';
import { OrderProgressBarComponent } from './order-progress-bar/order-progress-bar.component';
import { OrderProgressColorPipe } from './_pipes/order-progress-color.pipe';
import { ProgressBarColor } from './_directives/ProgressBarColor.directive';
import { SelectLanguagePipe } from './_pipes/select-language.pipe';
import { CapsWarningPipe } from './_pipes/caps-warning.pipe';
import { UserRatingComponent } from './user-rating/user-rating.component';
import { OrderStatusChangeLogComponent } from './order-status-change-log/order-status-change-log.component';
import { SelectOnFocusDirective } from './_directives/select-on-focus.directive';
import { PhoneNumberPipe } from './_pipes/phone-number.pipe';
import { CustomerEditComponent } from './customer-edit/customer-edit.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { TookanModule } from './tookan/tookan.module';
import { NumericalSortPipe } from './_pipes/numerical-sort.pipe';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { GetTableByIDPipe } from './_pipes/getTableByID.pipe';
import { ClickStopPropagationDirective } from './_directives/click-stop-propagation.directive';
import { ElapsedMinutesPipe } from './_pipes/elapsed-minutes.pipe';
import { OrderItemColorDirective } from './order-summary/order-item-color.directive';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { SearchClientsComponent } from './search-clients/search-clients.component';
import { FieldPipe } from './_pipes/field.pipe';
import { DeviceIDInterceptor } from './deviceID.interceptor';
import { STORAGE_SERVICE } from './_interface/storage';
import { environment } from 'src/environments/environment';
import { StorageService } from './_services/storage.service';
import { CrossDomainStorageService } from './_services/cross-domain-storage.service';
import { PWANotificationProvider } from './_services/pwaNotificationProvider.service';
import { NOTIFICATION_PROVIDER } from './_interface/notificationProvider';
import { SwPush } from '@angular/service-worker';
import { BeepService } from './_services/beep.service';
import { NativeNotificationProvider } from './_services/nativeNotificationProvider';
import { AuthorizationService } from './_services/authorization.service';
import { ReplaceNewLinePipe } from './_pipes/replace-new-line.pipe';
import { WebQRCodeScanerService } from './_services/web-qrcode-scaner.service';
import { QRCODE_SCANER } from './_interface/IQRCodeScanner';
import { NativeQRCodeScanerService } from './_services/native-qrcode-scaner.service';
import { HasInvalidDetailsPipe } from './order-details/has-invalid-details.pipe';
import { DisableAnimationDirective } from './_directives/disableAnimation.directive';
import { PinLoginComponent } from './pin-login/pin-login.component';
import { SplitStringPipe } from './_pipes/SplitString.pipe';
import { CookieService } from 'ngx-cookie-service';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ConcurrentLimitMonitorService } from './_services/concurrentLimitMonitor.service';
import { GetUserFromIDPipe } from './_pipes/get-user-from-id.pipe';
import { ClientEditDialogComponent } from './client-edit-dialog/client-edit-dialog.component';
import { WebsocketConnectionListComponent } from './websocketConnectionList/websocketConnectionList.component';
import { DeviceEditComponent } from './device-edit/device-edit.component';
import { FilterWSConnectionByCompanyPipe } from './websocketConnectionList/filterWSconnectionByCompany.pipe';
import { FilterByApplicationNamePipe } from './websocketConnectionList/filterByApplicationName.pipe';
import { VarDirective } from './_directives/Var.directive';
import { FirstGroupNamePipe } from './_pipes/FirstGroupName.pipe';
import { LangChangeEvent, MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MyMissingTranslationHandler } from './missingTranslationHandler';
import { SortLanguagesPipe } from './translation/SortLanguages.pipe';
import { SelectDueDateComponent } from './selectDueDate/selectDueDate.component';
import { MaterialModule } from '../angularMaterial/material/material.module';
import { JsonParsePipe } from './_pipes/jsonParse.pipe';
import { EnumItemNamePipe } from './enum-checkbox-selector/EnumItemName.pipe';
import { IsInvalidOrderPipe } from './_pipes/isInvalidOrder.pipe';
import { AppVersionInterceptor } from './appVersion.interceptor';
import { LoadImagePipe } from './_pipes/loadImage.pipe';
import { SortByNamePipe } from './_pipes/sortByName.pipe';
import { OfflineDetectorInterceptor } from './offlineDetectorInterceptor';
import { InactivityMonitorComponent } from './InactivityMonitor/InactivityMonitor.component';
import { NativeLogger } from './_helpers/nativeLogger';
import { LoggerModule, NGXLogger, NgxLoggerLevel, TOKEN_LOGGER_WRITER_SERVICE } from 'ngx-logger';
import { DBLogger } from './_helpers/dbLogger';
import { SendLogService } from './_services/sendLog.service';
import { PleaseWaitComponent } from './please-wait/please-wait.component';
import { LayoutItemComponent } from './tableLayout/layoutItem/layoutItem.component';
import { MatContextMenuTriggerDirective } from './_directives/MatContextMenuTrigger.directive';
import { TableLayoutComponent } from './tableLayout/layout/layout.component';
import { TransformRotateDirective } from './tableLayout/TransformRotate.directive';
import { ResizeDirective } from './_directives/resize-directive';

registerLocaleData(localeSr);

export function httpCoreTranslationLoaderFactory(http: HttpClient) {
  //  console.log("httpTranslationLoaderFactory");
  return new TranslateHttpLoader(
    http,
    `./assets/i18n/core/`,
    '.json'
  );
}

export let AppInjector: Injector;
@NgModule({
  declarations: [
    TranslationComponent,
    NameTranslationEditComponent,
    OrderStatusPipe,
    EnumCheckboxSelectorComponent,
    EnumEnumeratorPipe,
    HasPermissionPipe,
    AlertDialogComponent,
    IsBusyDirective,
    InvoiceDetailsComponent,
    ToBundleNamePipe,
    QRCodeScannerComponent,
    ToAllergenClassPipe,
    ToAllergenNamePipe,
    InViewDirective,
    GetProductByIDPipe,
    ParentProductPipe,
    GroupOrderItemModifiersPipe,
    OrderItemDetailsComponent,
    ProductsInGroupPipe,
    ToGroupIconClassPipe,
    ChildProductsPipe,
    ToProductsPipe,
    SortPipe,
    LoginComponent,
    LogoutComponent,
    RouteLoadingComponent,
    SaveButtonComponent,
    OrderStatusSelectorComponent,
    TableGroupsPipe,
    NoWebsocketConnectionComponent,
    StickyThingDirective,
    StatusAvailablePipe,
    ToProductPipe,
    TimeSincePipe,
    ReduceStringArrayPipe,
    SortOrdersByDatePipe,
    ToggleFullScreenComponent,
    VirtualKeyboardComponent,
    FullNamePipe,
    OrderDetailsComponent,
    GroupBoxComponent,
    ToDeliveryTypeNamePipe,
    OrderDeliveryTypeIconComponent,
    OrderDetailsDialogComponent,
    VersionComponent,
    ChargeComponent,
    PaymentMethodNamePipe,
    GoogleSigninButtonDirectiveDirective,
    IsPaymentTypeEnabledPipe,
    HasAdminPermissionPipe,
    ImageSizePipe,
    EventNamePipe,
    WoltOrderDetailsComponent,
    PropertyItemComponent,
    WoltOrderStatusNamePipe,
    SelectPreparationTimeComponent,
    PreserveNewLinePipe,
    SelectRejectReasonComponent,
    DialogTitleComponent,
    ProviderIconComponent,
    DragToScrollDirective,
    OrderIconsComponent,
    MrDOrderDetailsComponent,
    CodeSigninComponent,
    SplitLettersDirective,
    OrderProgressBarComponent,
    OrderProgressColorPipe,
    ProgressBarColor,
    FilterOrdersByStatusPipe,
    SelectLanguagePipe,
    CapsWarningPipe,
    UserRatingComponent,
    OrderStatusChangeLogComponent,
    SelectOnFocusDirective,
    PhoneNumberPipe,
    CustomerEditComponent,
    CustomerDetailsComponent,
    NumericalSortPipe,
    OrderSummaryComponent,
    GetTableByIDPipe,
    ClickStopPropagationDirective,
    ElapsedMinutesPipe,
    OrderItemColorDirective,
    SearchClientsComponent,
    ClientEditComponent,
    FieldPipe,
    ReplaceNewLinePipe,
    HasInvalidDetailsPipe,
    DisableAnimationDirective,
    DomChangedDirective,
    PinLoginComponent,
    SplitStringPipe,
    GetUserFromIDPipe,
    ClientEditDialogComponent,
    WebsocketConnectionListComponent,
    DeviceEditComponent,
    FilterWSConnectionByCompanyPipe,
    FilterByApplicationNamePipe,
    VarDirective,
    FirstGroupNamePipe,
    SortLanguagesPipe,
    SelectDueDateComponent,
    JsonParsePipe,
    EnumItemNamePipe,
    IsInvalidOrderPipe,
    LoadImagePipe,
    SortByNamePipe,
    InactivityMonitorComponent,
    PleaseWaitComponent,
    TableLayoutComponent,
    LayoutItemComponent,
    MatContextMenuTriggerDirective,
    TransformRotateDirective,
    ResizeDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ZXingScannerModule,
    MatSnackBarModule,
    HttpClientModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    SweetAlert2Module.forChild(),
    MatKeyboardModule,
    DragDropModule,
    TookanModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpCoreTranslationLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      isolate: false,
      extend: true
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.TRACE,
      disableFileDetails: true,
    },
      {
        writerProvider:
        {
          provide: TOKEN_LOGGER_WRITER_SERVICE,
          useFactory: createLogger,
          deps: [Injector, CrossAppMessagingService]
        }
      }
    )
  ],
  exports: [
    TranslationComponent,
    NameTranslationEditComponent,
    OrderStatusPipe,
    EnumCheckboxSelectorComponent,
    EnumEnumeratorPipe,
    HasPermissionPipe,
    AlertDialogComponent,
    IsBusyDirective,
    ToBundleNamePipe,
    QRCodeScannerComponent,
    ToAllergenClassPipe,
    ToAllergenNamePipe,
    InViewDirective,
    GetProductByIDPipe,
    ParentProductPipe,
    GroupOrderItemModifiersPipe,
    OrderItemDetailsComponent,
    ProductsInGroupPipe,
    ToGroupIconClassPipe,
    ChildProductsPipe,
    ToProductsPipe,
    SortPipe,
    LoginComponent,
    LogoutComponent,
    RouteLoadingComponent,
    SaveButtonComponent,
    OrderStatusSelectorComponent,
    TableGroupsPipe,
    NoWebsocketConnectionComponent,
    StickyThingDirective,
    StatusAvailablePipe,
    ToProductPipe,
    TimeSincePipe,
    ReduceStringArrayPipe,
    SortOrdersByDatePipe,
    ToggleFullScreenComponent,
    FullNamePipe,
    OrderDetailsComponent,
    GroupBoxComponent,
    ToDeliveryTypeNamePipe,
    OrderDeliveryTypeIconComponent,
    OrderDetailsDialogComponent,
    VersionComponent,
    ChargeComponent,
    PaymentMethodNamePipe,
    HasAdminPermissionPipe,
    ImageSizePipe,
    EventNamePipe,
    DialogTitleComponent,
    ProviderIconComponent,
    DragToScrollDirective,
    OrderIconsComponent,
    OrderProgressBarComponent,
    FilterOrdersByStatusPipe,
    SelectLanguagePipe,
    CapsWarningPipe,
    SelectOnFocusDirective,
    UserRatingComponent,
    PropertyItemComponent,
    PhoneNumberPipe,
    CustomerEditComponent,
    CustomerDetailsComponent,
    TookanModule,
    NumericalSortPipe,
    OrderSummaryComponent,
    GetTableByIDPipe,
    ReplaceNewLinePipe,
    FieldPipe,
    DisableAnimationDirective,
    DomChangedDirective,
    PinLoginComponent,
    SplitStringPipe,
    GetUserFromIDPipe,
    ClientEditComponent,
    WebsocketConnectionListComponent,
    VarDirective,
    FirstGroupNamePipe,
    JsonParsePipe,
    TranslateModule,
    IsInvalidOrderPipe,
    LoadImagePipe,
    SortByNamePipe,
    InactivityMonitorComponent,
    PleaseWaitComponent,
    TableLayoutComponent,
    LayoutItemComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AccessDeniedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineDetectorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DeviceIDInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppVersionInterceptor, multi: true },
    AuthTokenLoadedResolver,
    { provide: LOCALE_ID, useValue: 'sr' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: STORAGE_SERVICE, useFactory: createStorageService, deps: [Injector] },
    { provide: NOTIFICATION_PROVIDER, useFactory: createNotificationProvider, deps: [Injector] },
    { provide: QRCODE_SCANER, useFactory: createQRCodeScaner, deps: [Injector] },
    CookieService,
    {
      provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [SendLogService,
        ConcurrentLimitMonitorService,
        AppVersionCheckService],
      multi: true
    },
  ]
})
export class CoreModule {
  constructor(private injector: Injector,
    log: NGXLogger,
    private translationService: TranslateService) {
    AppInjector = this.injector;

    log.info("Starting app " + environment.appVersion);

    this.translationService.store.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        //  if (!environment.production) {
        //   console.log(' ==> LazyLoadedModule CORE ', lang);
        //  }
        this.translationService.use(lang.lang);
      }
    );
  }
}

function createStorageService(injector: Injector) {
  if ((environment as any)["nativeApp"] === true) {
    return new StorageService();
  } else {
    return new CrossDomainStorageService();
  }
}



function createLogger(injector: Injector, crossAppMessaging: CrossAppMessagingService) {
  const url = new URL(window.location.href);

  var isNative = url.searchParams.get('native');
  if (isNative == null) {
    isNative = localStorage.getItem("nativeApp");
  }
  if (isNative == "1") {
    return new NativeLogger(crossAppMessaging);
  } else {
    return new DBLogger();
  }
}


function createNotificationProvider(injector: Injector) {
  if ((environment as any)["nativeApp"] === true) {
    var s = inject(CrossAppMessagingService)
    return new NativeNotificationProvider(s);
  } else {
    var swPush = inject(SwPush);
    var beep = inject(BeepService);

    return new PWANotificationProvider(swPush, beep);
  }
}

function createQRCodeScaner(injector: Injector) {
  if ((environment as any)["nativeApp"] === true) {
    var s = inject(CrossAppMessagingService)
    return new NativeQRCodeScanerService(s);

  } else {

    return new WebQRCodeScanerService(injector);
  }
}

