<ng-container *ngIf="showFilter">
  <mat-chip-list class="appFilter">
    <mat-chip [selected]="filterApp === 'kd'" (click)="applyFilter('kd')">
      KD
    </mat-chip>
    <mat-chip [selected]="filterApp === 'cd'" (click)="applyFilter('cd')">
      CD
    </mat-chip>
    <mat-chip [selected]="filterApp === 'w'" (click)="applyFilter('w')">
      Waiter
    </mat-chip>
    <mat-chip [selected]="filterApp === 'a'" (click)="applyFilter('a')">
      Admin
    </mat-chip>
    <mat-chip [selected]="filterApp === 'api'" (click)="applyFilter('api')">
      API
    </mat-chip>
  </mat-chip-list>
</ng-container>

<table class="table">
  <thead>
    <tr>
      <th>Korisnik</th>
      <th>Firma</th>
      <th>IP adresa</th>
      <th>Kreirana</th>
      <th>Poslednja poslata poruka</th>
      <th>Poslednja primljena poruka</th>
      <th>POS</th>
      <th>App version</th>
      <th>Device ID</th>
      <th>Device name</th>
      <th></th>
    </tr>
  </thead>
  <tr
    *ngFor="
      let item of connections
        | appFilterByCompany : companyID
        | appFilterByApplicationName : filterApp
    "
  >
    <td>
      {{ item.userName }}
    </td>
    <td>
      {{ item.companyName }}
    </td>
    <td>{{ item.ipAddress }}</td>
    <td>
      {{ item.created | date : "dd.MM. HH:mm:ss" }}
    </td>
    <td>
      {{ item.lastSentMessage | date : "dd.MM. HH:mm:ss" }}
    </td>
    <td>
      {{ item.lastReceivedMessage | date : "dd.MM. HH:mm:ss" }}
    </td>
    <td>{{ item.isPos ? item.posName : "" }}</td>
    <td>
      <ng-container *ngIf="item.device?.nativeAppVersion">
        {{ item.device?.nativeAppVersion }} /
      </ng-container>
      {{ item.appVersion }}
      <mat-icon
        *ngIf="item.device?.nativeApp"
        [title]="item.device?.nativeAppVersion"
        >smartphone</mat-icon
      >
      <mat-icon *ngIf="item.device?.hasPrinter">printer</mat-icon>
      <mat-icon
        *ngIf="item.device?.hasPayment"
        [title]="item.device?.cardPaymentProvider"
        >credit_card</mat-icon
      >
    </td>
    <td>
      <a [href]="" (click)="editDevice(item.device?.deviceID)">{{
        item.device?.deviceID
      }}</a>
    </td>
    <td>
      {{ item.device?.name }}
    </td>
    <td>
      <a [href]="" [swal]="confirm" (confirm)="endSession(item.sessionID)"
        >Logout</a
      >
    </td>
  </tr>
</table>
<swal
  #confirm
  title="Da li ste sigurni?"
  icon="question"
  [showCancelButton]="true"
  cancelButtonText="Ne"
  confirmButtonText="Da"
  text="Ova akcija je nepovratna!"
>
</swal>
