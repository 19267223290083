import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, Output } from '@angular/core';
import { ConnectionMetadata } from '../_interface/ConnectionMetadata';
import { MatDialog } from '@angular/material/dialog';
import { DeviceEditComponent } from '../device-edit/device-edit.component';
import { SessionService } from '../_services/session.service';
import { Subject, takeUntil } from 'rxjs';
import { WSConnectionService } from '../_services/wsConnection.service';
import { WSService } from '../_services/ws.service';

@Component({
  selector: 'app-websocket-connection-list',
  templateUrl: './websocketConnectionList.component.html',
  styleUrls: ['./websocketConnectionList.component.scss'],
})
export class WebsocketConnectionListComponent implements OnDestroy, AfterViewInit {
  @Input() companyID?: number | null;
  @Input() showFilter = false;
  filterApp = '';

  connections?: ConnectionMetadata[] | null;
  @Output() changed = new Subject<ConnectionMetadata[]>();

  destroyed = new Subject<void>();


  constructor(
    private sessionService: SessionService,
    private dialog: MatDialog,
    public connectionService: WSConnectionService,
    public session: SessionService,
    private ws: WSService
  ) {

  }
  ngAfterViewInit(): void {
    this.loadConnections();
  }



  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }



  loadConnections() {

    if (this.companyID) {
      var subscription = this.connectionService.connectionsForCompany(this.session.companyID);
    }
    else {
      var subscription = this.connectionService.all();
    }

    return subscription.subscribe(
      x => {
        this.connections = x;
        if (this.connections) {
          this.changed.next([...this.connections]);
        }

        this.observeConnectionChanges();
      }
    )
  }

  observeConnectionChanges() {

    this.ws.observe("socketDisonnected").pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      received => {
        var meta = received.data as ConnectionMetadata;
        if (meta) {
          if (this.connections) {

            var index = this.connections?.findIndex(i => i.id == meta.id);
            if (index > -1) {
              this.connections.splice(index, 1);
              this.changed.next([...this.connections]);
            }
          }
        }
      }
    );

    this.ws.observe("socketMetadataChange").pipe(
      takeUntil(this.destroyed)
    ).subscribe(
      received => {
        var meta = received.data as ConnectionMetadata;
        if (meta) {
          this.socketChanged(meta);
        }
      }
    )
  }

  socketChanged(meta: ConnectionMetadata) {
    if (!this.connections) {
      return;
    }
    var index = -1;

    var conn = this.connections.find(i => i.id == meta.id);
    if (conn) {
      index = this.connections.indexOf(conn);
    }

    if (index != -1) {
      this.connections[index] = meta;
    }
    else {
      this.connections = [meta, ...this.connections];
    }

    this.changed.next([...this.connections]);
  }




  editDevice(id: number | undefined) {
    if (!id) {
      return;
    }

    this.dialog.open(DeviceEditComponent, { data: { deviceID: id } });
  }

  endSession(sessionID: string) {
    this.sessionService.endSession(sessionID).subscribe({

    });
  }

  applyFilter(e: any) {
    if (this.filterApp === e) {
      this.filterApp = "";
    } else {
      this.filterApp = e;
    }
  }

}
