import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Directive({
  selector: '[appResize]',
})
export class ResizeDirective implements OnInit, OnDestroy {
  @Output() resized = new EventEmitter<{ width: number, height: number }>();

  resizeObserver?: Subscription;

  constructor(private element: ElementRef) { }

  ngOnInit(): void {
    this.resizeObserver = this.resizeObservable(this.element.nativeElement)
      .pipe(
    //   debounceTime(50),
        map((entries) => entries[0])
      )
      .subscribe((entry) => {
        this.resized.emit({ width: entry.contentRect.width, height: entry.contentRect.height });
      });
  }

  ngOnDestroy(): void {
    this.resizeObserver?.unsubscribe();
  }

  resizeObservable(el: HTMLElement): Observable<ResizeObserverEntry[]> {
    return new Observable((subscriber) => {
      const observer = new ResizeObserver((entries) => {
        subscriber.next(entries);
      });

      observer.observe(el);

      return function unsubscribe() {
        observer.unobserve(el);
      };
    });
  }
}
