import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, debounceTime, filter, flatMap, interval, switchMap, switchMapTo, takeUntil, tap, timer } from 'rxjs';
import { AuthorizationService } from 'src/app/core/_services/authorization.service';

@Component({
  selector: 'app-inactivity-monitor',
  templateUrl: './InactivityMonitor.component.html',
  styleUrls: ['./InactivityMonitor.component.scss']
})
export class InactivityMonitorComponent implements OnDestroy, OnInit {
  destroy = new Subject<void>();
  moveSubject = new Subject<void>();
  userActive = true;

  /// Koliko sekundi da čeka pre nego što odjavi korisnika
  @Input()
  duration = 5;

  @Input() text = "Kraj";
  remaining = 10;

  @Output() elapsed = new EventEmitter<void>();
  private _paused = false;

  constructor(
    auth: AuthorizationService,
    router: Router
  ) {

    this.moveSubject.pipe(
      takeUntil(this.destroy),
      tap((x) => {
        this.userActive = false
        this.remaining = this.duration;
      }),
      filter(() => this.duration > 0),
      switchMap(() => interval(1000))
    ).pipe(
      filter(() => this.remaining > 0 && !this._paused)
    ).subscribe(
      (val) => {
        //  console.log(val);
        this.remaining--;
        if (this.remaining == 0) {
          this.elapsed.emit();
          //          router.navigate(["/logout"]);
        }
      }
    )
  }
  ngOnInit(): void {

    this.moveSubject.next();
  }

  pause() {
    this._paused = true;
  }

  resume() {
    this._paused = false;
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:click', ['$event'])
  setActive() {
    this.userActive = true;
    this.moveSubject.next();
  }

}
