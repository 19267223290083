import { Inject } from '@angular/core';
import { INotificationProvider, INotificationToken } from "../_interface/notificationProvider";
import { OrderStatus } from "../_interface/order";
import { CrossAppMessagingService, IMessage, } from "./crossApp-messaging.service";
import { NGXLogger } from 'ngx-logger';


export class NativeNotificationProvider implements INotificationProvider {
  private cancelWaiterCallFn = (tableID: number) => { };
  private setOrderStatusFn = (orderID: number, orderStatus: OrderStatus) => { };

  constructor(
  private crossAppMessaging: CrossAppMessagingService,
  ) {
  }

  cancelWaiterCall(fn: (tableID: number) => void): void {
    this.cancelWaiterCallFn = fn;
  }
  setOrderStatus(fn: (orderID: number, orderStatus: OrderStatus) => void): void {
    this.setOrderStatusFn = fn;
  }

  getToken(): Promise<INotificationToken | null> {
    var msg = {
      action: "notification_getToken"
    };

    return this.crossAppMessaging.sendRawMessage(msg, true).then(
      msg => {
        if (msg == null) {
          return null;
        }

        msg = msg.replace(/'/g, '"');

        var jmsg = JSON.parse(msg);
        if (jmsg == null) {
    //      this.logger.log("Token null");
          return null;
        }

        var token: INotificationToken = {
          token: jmsg.token,
          provider: jmsg.provider
        };
        return token;
      }
    );
  }

  removeNotification(tag: string): void {
    var msg: IMessage = {
      action: "notification_removeNotification",
      data: tag
    };

    this.crossAppMessaging.sendRawMessage(msg);
  }


}
