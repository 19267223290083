import { PaymentType } from './payment';
import { DeliveryType, OrderStatus } from "./order";

export interface ICompany {
  companyID: number;
  name: string;
  facilityName: string;
  description: string;
  address: string;
  lastKnownIPAddress: string;
  phone: string;
  menuDate: string;
  ownerID: number;
  bundleRenewDate: Date | null;
  renewDateExtension: number;
  isPublic: boolean;
  created: Date;
  zipCode: string;
  city: string;
  taxNo: string;
  bundleID: number;
  ownerName: string;
  identificationNo: string;
  eMail: string;
  posName: string;
  deleted: boolean;
  images: CompanyImage[];
}

export interface WiFi {
  ssid: string;
  password: string;
  hidden: boolean;
  authentication: string;
}

export interface CompanyAdminSettings {
  allowMenuModification: boolean;
  allowGlovo: boolean;
  allowMrD: boolean;
  allowWolt: boolean;
  allowGloriafood: boolean;
  allowPBX: boolean;
  eMenuPro: boolean;
  disabled: boolean;
  allowItemAvailabilityChange: boolean;

  personalizedUserApp: boolean;
  userAppName: string;
  userAppShortName: string;

  customerDisplayConcurrencyLimit: number;
  kitchenDisplayConcurrencyLimit: number;
  waiterAppConcurrencyLimit: number;
  waiterAppProConcurrencyLimit: number;

  allowTableStar: boolean;
}
export interface CompanySettings extends CompanyAdminSettings {
  allowedIP: string;
  allowOnlyLocalIP: boolean;
  WiFi: WiFi;
  allowedDeliveryType: DeliveryType[];
  allowTableTotal: boolean;
  allowTableTotalWithoutOrder: boolean;
  allowCallWaiter: boolean;
  requestConfirmationForCallWaiter: boolean;
  allowedOrderStatusNotifications: OrderStatus[];
  allowedOrderStatus: OrderStatus[];
  requireAuthenticatedUserForOrder: boolean;
  requireAuthenticatedUserForView: boolean;
  allowedUsers: string[];
  blockedUsers: string[];
  allowPaymentRequest: boolean;
  requestConfirmationForPaymentRequest: boolean;
  showTimeLimitedProducts: boolean;
  posTimeout: number;
  requirePOSReponse: boolean;
  allowChargeOnWaiterApp: boolean;
  showOrderNoOnWaiterApp: boolean;
  allowWaiterDebtInfoInWaiterApp: boolean;
  requireDeliveryInfoForPickupInWaiterApp: boolean;
  groupOrdersByGroupInUserApp: boolean;
  numberOfGroupsInTable: number;
  repeatSoundForPendingNotifications: boolean;
  requireAuthenticatedUserForPickaupAndDelivery: boolean;
  alowChargeInOrderDetails: boolean;
  disableMultipleEmployeeSessions: boolean;
  sendOrderPreparationTime: boolean;
  allowedPaymentTypes: PaymentType[];
  autoShowTableTotal: boolean;
  showPictures: boolean;
  showOnlyCompanyLogo: boolean;

  mrDEnabled: boolean;
  mrDUserName: string;
  mrDPassword: string;

  woltEnabled: boolean;
  woltAPIKey: string;
  woltVenueID: string;
  woltSecret: string;
  woltChangeOrderStatus: boolean;
  woltChangeProductStatus: boolean;
  woltUsername: string;
  woltPassword: string;
  wolt_OrderPaidStatus: OrderStatus;

  glovoEnabled: boolean;
  glovoStoreID: string;
  glovoToken: string;

  hideItemPriceInNewOrderInWaiterApp: boolean;
  disableChargeWithPendingOrderInWaiterApp: boolean;

  ordersDisplayPendingWidth: number;
  ordersDisplayReadyWidth: number;
  ordersDisplay_ShowTimer: boolean;
  ordersDisplay_HiddenProviders: string;

  primaryColor: string;
  gpsLocation: GPSLocation;
  availableLanguages: string[];

  pbxEnabled: boolean
  pbxKey: string;
  pbxPhoneNumberPattern: string;

  gloriafood_Enabled: boolean;
  gloriafood_RestaurantKey: string;
  gloriafood_MasterKey: string;

  requireDeliveryInfoForPickupInUserApp: boolean;

  allowExternalProvider: boolean;
  externalProvider_Enabled: boolean;
  externalProvider_Key: string;
  externalProvider_Name: string;
  externalProvider_WebhookURL: string;
  externalProvider_OrderPaidStatus: OrderStatus

  allowTookan: boolean;
  tookan_Key: string;
  tookan_Enabled: boolean;

  parentCompanyID?: number;

  requestConfirmationForOrderItemStatusChange: boolean;
  showPayedInstantOrders: boolean;

  workingHours: string;
  facebookURL: string;
  googlePlaceID: string;
  instagramURL: string;
  twitterURL: string;
  tripAdvisorURL: string;

  waiter_sendOrderReadyNotification: boolean;
  currency: string;

  pinToken: string;

  tableStarApiKey: string;

  eGazdaUserName: string;
  eGazdaPassword: string;

  dueDateSelectorType: DueDateSelectorType;
  waiter_ShowDirectOrders: boolean;
}

export enum ImageType {
  logo = "logo",
  background = "background",
  QRCodeLogo = "QRCodeLogo",
  userAppIcon = "userAppIcon",
  printImage = "printImage",
  pwaIcon72 = "pwaIcon72",
  pwaIcon96 = "pwaIcon96",
  pwaIcon128 = "pwaIcon128",
  pwaIcon144 = "pwaIcon144",
  pwaIcon152 = "pwaIcon152",
  pwaIcon192 = "pwaIcon192",
  pwaIcon384 = "pwaIcon384",
  pwaIcon512 = "pwaIcon512",
}
export interface CompanyImage {
  type: ImageType;
  url: string;
}

export function createCompany(): ICompany {
  return {
    companyID: 0,
    name: '',
    facilityName: '',
    description: '',
    address: '',
    lastKnownIPAddress: '',
    phone: '',
    menuDate: '',
    ownerID: 0,
    bundleRenewDate: null,
    renewDateExtension: 0,
    isPublic: false,
    created: new Date(),
    zipCode: '',
    city: '',
    taxNo: '',
    bundleID: 0,
    ownerName: '',
    identificationNo: '',
    eMail: '',
    posName: 'POS',
    deleted: false,
    images: []
  }
}

export interface GPSLocation {
  lng: number;
  lat: number;
}


export enum DueDateSelectorType {
  Minutes = 0,
  Days = 1
}
