<div *ngIf="order">
  <div class="invalidOrder error" *ngIf="order | isInvalidOrder">
    {{ "invalidOrder" | translate }}
  </div>
  <div class="summary">
    <div class="provider" *ngIf="order.provider">
      {{ "provider" | translate }}:
      <app-provider-icon [provider]="order.provider"></app-provider-icon>
      <mat-icon class="info" (click)="showProviderDetails()">info</mat-icon>
    </div>
    <div class="ids">
      <div>
        ID: <span class="primaryColor">#{{ order.orderNo }}</span> ({{
          order.orderID
        }})
        <mat-icon class="info" (click)="printOrder()">print</mat-icon>
        <span *ngIf="order.isPaid" class="isPaid">
          - {{ "paid" | translate }}</span
        >
      </div>
      <div>
        {{ order.created | date : "dd.MM.yyyy HH:mm:ss" }}
      </div>
    </div>
    <div *ngIf="order.originCompany" class="originCompany">
      {{ "originCompany" | translate }}:
      <span class="name">{{ order.originCompany.name }}</span>
    </div>
    <div class="users">
      <div class="createdBy">
        {{ "createdBy" | translate }}:
        <span class="name">{{ order.createdBy | fullName }} </span>
        <mat-icon
          class="verified"
          color="primary"
          *ngIf="order.createdBy?.verified"
          >verified</mat-icon
        >
      </div>
      <div>
        {{ "acceptedBy" | translate }}:
        <span class="name">{{ order.waiter?.name }}</span>
      </div>
    </div>
    <div *ngIf="order.customer">
      <div class="customer">
        <span class="nameContainer">
          {{ "customer" | translate }}:
          <span class="name">{{
            order.customer.name || "Korisnik " + order.customer.customerID
          }}</span></span
        >
        <mat-icon class="info" (click)="showCustomerDetails()">info</mat-icon>
      </div>
      <div class="rating">
        {{ "rating" | translate }}:
        <app-user-rating [rating]="order.customer"></app-user-rating>
      </div>
    </div>
    <div *ngIf="order.rejectReason">
      {{ "rejectReason" | translate }}:
      <span class="name">{{ order.rejectReason }}</span>
    </div>
  </div>
  <div class="details">
    <div
      class="warning orderNote"
      *ngIf="order.note"
      [innerHTML]="order.note | preserveNewLine"
    ></div>

    <app-group-box title="{{ 'orderedProducts' | translate }}">
      <ul class="productList">
        <li *ngFor="let item of order.items">
          <app-order-item-details [item]="item"></app-order-item-details>
        </li>
      </ul>

      <div class="total">
        {{ "totalAmount" | translate }}:
        <span class="price"
          ><span>{{
            (session.companySettings | async)?.currency ||
              (appSettingsService.current | async)?.currency
          }}</span>
          {{ order.total | number : "1.2" }}
        </span>
      </div>
    </app-group-box>
    <app-group-box
      *ngIf="order.invalidOrderDetails"
      title="Neispravne/nepovezane stavke"
      class="invalidOrderDetails"
    >
      <ng-container *ngIf="order.invalidOrderDetails.items?.length">
        {{ "products" | translate }}
        <ul class="productList">
          <ng-container *ngFor="let item of order.invalidOrderDetails.items">
            <li>
              {{ item.quantity }}x {{ item.name }} -
              {{ item.price | number : "1.2" }}
            </li>
            <li *ngFor="let invalid of item.modifiers" class="modifier">
              {{ invalid.name }} - {{ invalid.price | number : "1.2" }}
            </li>
          </ng-container>
        </ul>
      </ng-container>
      <ng-container *ngIf="order.items | hasInvalidDetails">
        {{ "modifiers" | translate }}
        <ul class="productList">
          <ng-container *ngFor="let item of order.items">
            <li
              *ngFor="let invalid of item.invalidOrderDetails"
              class="modifier"
            >
              {{ invalid.name }} - {{ invalid.price | number : "1.2" }}
            </li>
          </ng-container>
        </ul>
      </ng-container>
      <div class="total">
        {{ "totalOrderAmount" | translate }}:
        <span class="price"
          ><span>{{
            (session.companySettings | async)?.currency ||
              (appSettingsService.current | async)?.currency
          }}</span>
          {{ order.invalidOrderDetails.total | number : "1.2" }}
        </span>
      </div>
    </app-group-box>
    <app-group-box
      title="{{
        ('delivery' | translate) +
          ': ' +
          (order.deliveryType | toDeliveryTypeName | translate)
      }} "
      class="deliveryDetails"
      *ngIf="order.deliveryType !== DeliveryType.InHouse"
    >
      <div>{{ "name" | translate }}: {{ order.deliveryDetail?.name }}</div>
      <div>
        {{ "phoneNumber" | translate }}: {{ order.deliveryDetail?.phoneNumber }}
      </div>
      <div *ngIf="order.deliveryDetail?.address">
        {{ "address" | translate }}: {{ order.deliveryDetail?.address }}
      </div>
      <div *ngIf="order.deliveryDetail?.note">
        {{ "note" | translate }}: {{ order.deliveryDetail?.note }}
      </div>
    </app-group-box>
  </div>
  <app-group-box [titleTemplate]="title" class="status">
    <ng-template #title>
      <div class="statusGroupTitle">
        {{ "status" | translate }}: {{ order.status | orderStatus | translate }}
        <mat-icon class="info" (click)="showOrderStatusLog()">info</mat-icon>
      </div>
    </ng-template>
    <app-order-status-selector
      [order]="order"
      [disabled]="!canChangeOrderStatus"
      (statusChanged)="changeOrderStatus($event)"
    ></app-order-status-selector>
  </app-group-box>
</div>
