import { ApiResponse } from './../_interface/apiResponse';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SignInCode } from '../_interface/signiCode';
import { User } from '../_interface/user';
import { AuthorizationService } from './authorization.service';
import { GenericItemApiResponse } from '../_interface/apiResponse';

@Injectable({
  providedIn: 'root'
})
export class CodeSigninService {

  @Input()
  successLoginRedirect = '';

  constructor(private http: HttpClient, private authService: AuthorizationService) {

  }

  generate() {
    const apiAddress = environment.apiBase + 'SigninCode/generate';

    const response = this.http.get<SignInCode>(apiAddress);

    return response;
  }

  tryLogin(code: string, token: string) {
    const apiAddress = environment.apiBase + 'SigninCode/login';
    var data = {
      code,
      token
    };

    return this.http.post<GenericItemApiResponse<string>>(apiAddress, data).pipe(
      map(data => {
        return data.success && data.item;
      })

    );

  }


  acceptCode(code: string, userID: number) {
    const apiAddress = environment.apiBase + 'SigninCode/accept';
    var data = {
      code,
      userID
    };

    return this.http.post<ApiResponse>(apiAddress, data).pipe(
      map(
        val => {
          if (val.success) {
            return true;
          }
          throw val;
        }
      )
    );
  }
}
