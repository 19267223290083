import { CrossAppMessagingService } from './core/_services/crossApp-messaging.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Inject, inject } from '@angular/core';
import { AuthorizationService } from './core/_services/authorization.service';
import { environment } from 'src/environments/environment';
import { WSService } from './core/_services/ws.service';
import { DeviceService } from './core/_services/device.service';
import { SessionService } from './core/_services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Konobar - eMeni.rs';

  constructor(auth: AuthorizationService,
    router: Router,
    session: SessionService,
    crossAppMessaging: CrossAppMessagingService,
    device: DeviceService
  ) {

    session.selectedCompany.subscribe(company => {
      var msg = {
        action: "setCompany",
        data: company
      };
      crossAppMessaging?.sendRawMessage(msg);
    });

    auth.sessionExpired.subscribe(
      () => router.navigate(['/login'], { state: { invalidSession: true } })
    )

    if (environment.nativeApp) {
      inject(CrossAppMessagingService)?.sendRawMessage({
        action: "appLoaded"
      });

      inject(CrossAppMessagingService)?.sendRawMessage({
        action: "deviceID",
        data: device.deviceID
      });
    };
  }
}
