import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { map, Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericItemApiResponse } from '../_interface/apiResponse';
import { UserPermissions } from '../_interface/permissions';
import { AuthorizationService } from './authorization.service';
import { User } from '../_interface/user';

import { mapJsonToUsers } from 'src/app/core/_helpers/functions';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  _currentUserPermissions?: BehaviorSubject<UserPermissions | null>;

  constructor(private http: HttpClient,
    private authService: AuthorizationService) {

    authService.currentUser.subscribe(
      u => {
        if (u && this._currentUserPermissions) {
          this.loadCurrentUserPermissions();
        }
      }
    )
  }

  loadCurrentUserPermissions() {
    this.getAllPermissions(this.authService.currentUserID).subscribe(
      val => {
        this._currentUserPermissions?.next(val);
      }
    )
  }

  get currentUserPermissions() {
    if (!this._currentUserPermissions) {
      this._currentUserPermissions = new BehaviorSubject<UserPermissions | null>(null);

      this.loadCurrentUserPermissions()
    }

    return this._currentUserPermissions;
  }


  getAllPermissions(userID: number) {
    const apiAddress = environment.apiBase + `user/getAllPermissions/?userID=${userID} `;

    const response = this.http.get<GenericItemApiResponse<UserPermissions>>(apiAddress).pipe(
      map((data) => {
        if (data.success) {
          return data.item;
        }
        return null;
      })
    );

    return response;
  }


  getUser(userID: number): Observable<User | null> {
    const apiAddress = environment.apiBase + 'user/get/?userID=' + userID;

    const response = this.http.get<GenericItemApiResponse<User>>(apiAddress).pipe(
      map(data => {
        if (data.success) {
          if (!data.item) {
            return null;
          }
          return mapJsonToUsers([data.item])[0];
        }
        return null;

      })
    );

    return response;
  }

}
