<app-dialog-title>
  {{ "orderDetails" | translate }}
  <span class="orderNo">#{{ order?.orderNo }}</span>
</app-dialog-title>

<mat-dialog-content appDragToScroll>
  <app-order-details
    [groupInTable]="groupInTable"
    [order]="order"
    (statusChanged)="statusChanged()"
  ></app-order-details>
</mat-dialog-content>

<mat-dialog-actions
  *ngIf="(session.companySettings | async)?.alowChargeInOrderDetails"
>
  <button
    mat-raised-button
    color="accent"
    (click)="charge()"
    [IsBusy]="isBusy"
    [disabled]="isBusy"
  >
    Naplata
  </button>
</mat-dialog-actions>
