import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agent, CreateAgent } from '../_interface/agent';
import { GetTaskDetailsFromOrderIDRequest, GetTaskDetailsRequest } from '../_interface/task';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TookanProxyService {
  api_key: string = ""; //= "53666085f944090643572863514072471aecc1fe29d47d3b5f1400c3";
  url = environment.tookanURL;

  constructor(private http: HttpClient) { }

  editAgent(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "edit_agent", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  addAgent(object: CreateAgent) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "add_agent", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getAllAgents(object: any = {}) {
    object.api_key = this.api_key;

    return new Promise<Agent[]>((resolve, reject) => {
      this.sendPostRequest({ url: this.url + "get_all_fleets", data: object }, (response: any) => {
        if (response.status == 200) {
          resolve(response.data);
          return;
        }

        reject(response.message);
      });
    });
  };

  blockAndUnblockAgent(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "block_and_unblock_agent", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  viewAgentProfile(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "view_fleet_profile", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  updateAgentTags(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "update_fleet_tags", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getAgentTags(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_fleet_tags", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getAgentLogs(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_agent_duty_logs", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getAgentLocation(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_fleet_location", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  sendNotificationToAgent(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "send_notification", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getAgentSchedule(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_fleets_availability", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  assignAgentToTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "assign_fleet_to_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteAgent(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "delete_fleet_account", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  /*
   TASK API's
   */

  createTask<T>(object: any) {
    return new Promise<T>((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "create_task", data: object }, (response: any) => {
        if (response.status == 200) {
          resolve(response.data);
          return;
        }

        reject(response.message);
      },
        (err: any) => reject(err));
    });
  };

  createMultipleTasks(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "create_multiple_tasks", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getTaskDetails(object: GetTaskDetailsRequest) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_task_details", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getTaskDetailsFromOrderId(orderID: string | string[]) {
    var request: GetTaskDetailsFromOrderIDRequest = {
      order_ids: [...orderID],
      include_task_history: 0
    };

    return new Promise((resolve, reject) => {
      request.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_task_details_by_order_id", data: request }, (response: any) => {
        resolve(response);
      });
    });
  };

  editTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "edit_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  editMultipleTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "edit_multiple_tasks", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  updateTaskStatus(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "update_task_status", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  startTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "update_task_status", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  cancelTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "update_task_status", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  assignTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "assign_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  autoAssignTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "re_autoassign_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getAllTasks(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_all_tasks", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getTaskStatistics(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "user_task_stats", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "delete_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  /*
   TEAM API's
   */

  createTeam(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "create_team", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  updateTeam(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "update_team", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteTeam(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "delete_team", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getTeamDetails(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "view_teams", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getJobAndAgentDetails(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_job_and_fleet_details", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  /*
   MANAGER API's
   */

  createManager(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "add_manager", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  viewManager(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "view_all_manager", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteManager(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "delete_manager", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  /*
   CUSTOMER API's
   */

  getCustomers(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_all_customers", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  addCustomer(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "customer/add", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  editCustomer(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "customer/edit", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteCustomer(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "delete_customer", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  findCustomerWithPhone(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "find_customer_with_phone", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  findCustomerWithName(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "find_customer_with_name", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  viewCustomerProfile(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "view_customer_profile", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  /*
   MERCHANT API's
   */

  createMerchant(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "merchant/sign_up", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  viewMerchant(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "merchant/get_merchant", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  editMerchant(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "merchant/edit_merchant", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getMerchantDetails(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendGetRequest({ url: this.url + "merchant/get_merchant_data", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getMerchantReport(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendGetRequest({ url: this.url + "merchant/report", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  blockAndUnblockMerchant(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "merchant/block_merchant", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteMerchant(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "merchant/delete", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getAvailableMerchantAgents(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_available_agents", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  assignMerchantAgentToTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "assign_fleet_to_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  createMerchantTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "create_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  createMultipleMerchantTasks(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "create_multiple_tasks", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  editMerchantTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "edit_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  assignMerchantToTask(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "merchant/assign_merchant_to_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  getMerchantTeams(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "merchant/get_merchant_teams", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  /*
   GEOFENCE API's
   */

  addRegion(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "add_region", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  editRegion(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "edit_region", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  viewRegions(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "view_regions", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  viewRegionDetails(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "view_regions_only", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  removeRegionForAgent(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "remove_region_for_agent", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteRegion(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "remove_region", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  /*
   MISSION API's
   */
  createMission(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "create_mission_task", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  missionList(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "get_mission_list", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };

  deleteMission(object: any) {
    return new Promise((resolve, reject) => {
      object.api_key = this.api_key;
      this.sendPostRequest({ url: this.url + "delete_mission", data: object }, (response: any) => {
        resolve(response);
      });
    });
  };


  sendPostRequest(options: any, cb: any, reject?: any) {
    this.http.post(options.url, options.data, { responseType: 'text' })
      .pipe(
        map(
          val => {
            const regex = /\,(?!\s*?[\{\[\"\'\w])/g;
            const json = val.replace(regex, ''); // remove all trailing commas (`input` variable holds the erroneous JSON)
            const data = JSON.parse(json);
            return data;
          }
        )
      )
      .subscribe
      ({
        next: val => {
          cb(val);
        }
        , error: (err) => {
          console.log(err);
          if (reject) {
            reject(err);
          }
        }
      });
  }

  sendGetRequest(options: any, cb: any) {

    this.http.get(options.url).subscribe
      (val => {
        cb(val);
      });
  }
}
