import { ErrorCodes } from './_interface/apiResponse';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from './_services/authorization.service';
import { SessionService } from './_services/session.service';

@Injectable()
export class AccessDeniedInterceptor implements HttpInterceptor {
  constructor(private toast: ToastrService,
    private router: Router,
    private session: SessionService,
    private auth: AuthorizationService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessDeniedDescription: string | null;


    if (!req.url.startsWith(environment.apiBase)) {
      return next.handle(req);
    }

    if (req.headers.get('SkipApiResponseInterceptor') != null) {
      const newHeaders = req.headers.delete('SkipApiResponseInterceptor');
      const newRequest = req.clone({ headers: newHeaders });
      return next.handle(newRequest);
    }

    if (req.headers.get('accessDeniedDescription') != null) {
      accessDeniedDescription = req.headers.get('accessDeniedDescription');

      const newHeaders = req.headers.delete('accessDeniedDescription');
      req = req.clone({ headers: newHeaders });
    }

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.success !== undefined && !evt.body.success && evt.body.errorCode) {
            let message: string = '';

            switch (evt.body.errorCode as ErrorCodes) {
              case (ErrorCodes.ProductNotFound):
                message = 'Proizvod nije nađen';
                break;
              case (ErrorCodes.AccessDenied):
                message = 'Nemate prava ' + (accessDeniedDescription || 'za ovu akciju');
                break;
              case (ErrorCodes.InvalidCompany):
                message = 'Kompanija nije nađena';
                break;
              case (ErrorCodes.SubscriptionExpired):
                message = 'Pretplata je istekla';
                break;
              case (ErrorCodes.InvalidOperation):
                message = 'Akcija nije moguća';
                break;
              case (ErrorCodes.MailAlreadyRegistered):
                message = 'eMail adresa je već registrovana';
                break;
              case (ErrorCodes.InvalidToken): {
                message = 'Sesija istekla. Prijavite se opet';
                this.router.navigateByUrl('/logout');
                break;
              }
              case (ErrorCodes.UserDisabled): {
                message = 'Nalog zaključan';
                this.router.navigateByUrl('/logout');
                break;
              }
              case (ErrorCodes.AlreadyExtended): {
                message = 'Period je već produžen';
                break;
              }
              case (ErrorCodes.FileSizeTooBig): {
                message = 'Neodgovarajuća veličina fajla';
                break;
              }
              case (ErrorCodes.QRCodeTaken): {
                message = 'QR kod je već dodeljen';
                break;
              }
              case (ErrorCodes.TooManyGlobalQRCodes): {
                message = 'Previše QR kodova koji ne počinju sa "Q-"';
                break;
              }
              case (ErrorCodes.InvalidIPAddress): {
                message = 'Operacija nije dozvoljena sa vaše IP adrese';
                break;
              }
              case (ErrorCodes.InvalidSession): {
                this.auth.signOut().then(
                  () => this.router.navigate(['/login'], { state: { invalidSession: true } })
                );

                break;
              }
              case (ErrorCodes.NoResponseFromPOS): {
                var posName = this.session.selectedCompany.value?.posName || '';
                posName = posName.substring(0, posName.indexOf(':'));
                message = posName + " nije povezan. Proverite komunikaciju programa sa eMeni sistemom";
                break;
              }
              case (ErrorCodes.InvalidCode): {
                message = "Kod nije ispravan ili je već istekao";
                break;
              }
              default:
                message = 'Greška: ' + evt.body.errorCode + ' - ' + evt.body.errorDescription;
                break;
            }
            if (message) {
              this.toast.error(message);
            }
          }
        }
      })
    );
  }
}
