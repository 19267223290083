import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from 'src/app/core/_services/device.service';
import { Device } from '../_interface/device';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-device-edit',
  templateUrl: './device-edit.component.html',
  styleUrls: ['./device-edit.component.scss'],
})
export class DeviceEditComponent {
  device?: Device;
  formGroup: FormGroup;
  isBusy = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<DeviceEditComponent>,
    fb: FormBuilder) {

    this.formGroup = fb.group({
      deviceID: 0,
      name: '',
      pushNotificationPayload: '',
      pushNotificatonSource: '',
      waiterAppVersion: '',
      kitchenDisplayAppVersion: '',
      customerDisplayAppVersion: '',
      adminAppVersion: '',
      userAppVersion: ''
    });

    var deviceID = data.deviceID;

    this.isBusy = true;
    deviceService.getDevice(deviceID).subscribe((device) => {
      this.device = device;

      this.formGroup.patchValue(device);
    }
    ).add(() => this.isBusy = false);
  }

  submit() {
    if (this.formGroup.pristine) {
      this.dialogRef.close();
      return;
    }

    if (this.formGroup.valid) {
      this.isBusy = true;
      this.deviceService.updateDevice(this.formGroup.value).subscribe(
        {
          next: (result) => {
            if (result) {
              this.device!.name = this.formGroup.value.name;
              this.dialogRef.close();
              this.checkVersion();
            }
          }
        }
      )
        .add(() => this.isBusy = false);
    }
  }

  requestNativeLog() {
    if (this.device?.deviceID)
      this.deviceService.requestNativeLog(this.device?.deviceID);

  }

  checkVersion() {
    if (this.device?.deviceID)
      this.deviceService.checkVersion(this.device?.deviceID);

  }

  reload() {
    if (this.device?.deviceID)
      this.deviceService.reload(this.device?.deviceID);

  }
}
